import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/workspace/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/workspace/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/workspace/src/app/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/globals.css");
